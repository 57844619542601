<template>
  <div v-if="hasArticles">
    <div
      v-if="isHeadingVisible"
      class="md:flex md:items-baseline md:justify-between"
    >
      <BlockTitle :subtitle="subtitle" :title="title" />
      <RevButtonTiny v-if="hasPressLink" :to="pressLink" variant="secondary">
        {{ i18n(translations.button) }}
      </RevButtonTiny>
    </div>
    <RevCardCarousel
      desktop-card-width="wider"
      :next-alternative-text="i18n(translations.nextSlideText)"
      :prev-alternative-text="i18n(translations.prevSlideText)"
    >
      <PressArticleCard
        v-for="(article, index) in articles"
        :key="article.id"
        class="h-full w-full"
        :data-test="`presscarousel-slide-${index}`"
        v-bind="article"
        :loading="getLoadingAttribute(index)"
        :tracking="track({ position: index, articleTitle: article.text })"
      />
    </RevCardCarousel>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { PressCarouselProps } from '@backmarket/http-api/src/api-specs-content'
import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import { Loading } from '@backmarket/nuxt-layer-cms/ImageRenderer.constants'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCardCarousel } from '@ds/components/CardCarousel'

import PressArticleCard from './PressArticleCard.vue'
import translations from './PressCarousel.translations'
import { PRESS_LINKS_BY_COUNTRY } from './config'

const { articles, title, tracking } = withDefaults(
  defineProps<PressCarouselProps & ContentBlockProps>(),
  {
    title: '',
    subtitle: '',
    articles: () => [],
  },
)

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { countryCode } = useMarketplace().market
const i18n = useI18n()

const pressLink = computed(() => PRESS_LINKS_BY_COUNTRY[countryCode])
const hasPressLink = computed(() => typeof pressLink.value !== 'undefined')

const isHeadingVisible = computed(() => hasPressLink.value || title !== '')
const hasArticles = computed(() => articles.length > 0)

const getLoadingAttribute = (index: number): Loading => {
  return index === 0 ? Loading.Eager : Loading.Lazy
}

const track = ({
  position,
  articleTitle,
}: {
  position: number
  articleTitle: string
}) => {
  return {
    ...tracking,
    name: `${String(position)} - ${articleTitle}`,
  }
}
</script>
